import { Button, FlexProps, Wrap } from "@chakra-ui/react";
import { Link } from "gatsby";
import React, { createElement, FC } from "react";

import navbarLinks from "./navbarLinks";

const Navbar: FC<FlexProps> = (props) => {
  return (
    <Wrap shouldWrapChildren {...props}>
      {navbarLinks.map((link) => (
        <Button
          _hover={{
            bg: "gray.700",
          }}
          as={Link}
          bg="gray.900"
          color="white"
          key={link.route}
          leftIcon={"icon" in link ? createElement(link.icon) : undefined}
          size="sm"
          to={link.route}
        >
          {link.label}
        </Button>
      ))}
    </Wrap>
  );
};

export default Navbar;
