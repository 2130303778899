import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";
import { graphql, useStaticQuery } from "gatsby";
import { Link as GatsbyLink } from "gatsby";
import React, { FC, ReactNode } from "react";

import Picture from "../Picture";

import Navbar from "./components/Navbar";

const Layout: FC<{
  headerInsert?: ReactNode;
}> = ({ children, headerInsert }) => {
  const { datoCmsGlobal, site } = useStaticQuery<Gatsby.LayoutQuery>(graphql`
    query Layout {
      site {
        buildTime
      }

      datoCmsGlobal {
        background {
          url
        }

        logo {
          lg: gatsbyImageData(width: 88)
          sm: gatsbyImageData(width: 54)
        }
      }
    }
  `);

  if (!datoCmsGlobal || !site?.buildTime) throw Error();

  const overflow = 90;

  return (
    <Flex bg="gray.50" direction="column" minH="100vh">
      <Box>
        {headerInsert ? (
          <Flex
            bgColor="gray.800"
            bgImage={datoCmsGlobal.background?.url}
            bgPosition="center"
            bgSize="cover"
            direction="column"
            justify="space-between"
            p={{ base: 4, md: 6, lg: 8 }}
            pb={{ base: overflow, md: overflow, lg: overflow }}
          >
            {headerInsert}

            <Navbar justify={headerInsert ? "center" : "flex-start"} mb={8} />
          </Flex>
        ) : (
          <VStack
            bgColor="gray.800"
            bgImage={datoCmsGlobal.background?.url}
            bgPosition="center"
            bgSize="cover"
            mb={6}
            spacing={0}
          >
            <Link
              as={GatsbyLink}
              display={{ base: "initial", md: "none" }}
              m={6}
              mx="auto"
              to="/"
            >
              <Picture
                _hover={{
                  transform: "scale(1.03)",
                }}
                image={datoCmsGlobal.logo?.sm}
                transitionDuration="fast"
                transitionProperty="common"
                transitionTimingFunction="ease-out"
              />
            </Link>

            <HStack
              align="center"
              direction="row"
              p={8}
              pb={overflow}
              spacing={4}
            >
              <Link
                as={GatsbyLink}
                display={{ base: "none", md: "initial" }}
                to="/"
              >
                <Picture
                  _hover={{
                    transform: "scale(1.03)",
                  }}
                  image={datoCmsGlobal.logo?.sm}
                  transitionDuration="fast"
                  transitionProperty="common"
                  transitionTimingFunction="ease-out"
                />
              </Link>

              <Navbar justify={headerInsert ? "center" : "flex-start"} />
            </HStack>
          </VStack>
        )}
      </Box>

      <Box flexGrow={1} mt={-overflow} pb={12}>
        {children}
      </Box>

      <Flex
        align="center"
        bgColor="gray.800"
        bgImage={datoCmsGlobal.background?.url}
        bgPosition="center"
        bgSize="cover"
        direction="column"
        justify="space-between"
        py={10}
      >
        <Box mb={8}>{<Picture image={datoCmsGlobal.logo?.lg} />}</Box>

        <Text color="white" fontSize="sm" fontWeight="semibold">
          Dernière mise à jour :
        </Text>

        <Text color="white" fontSize="sm" mb={8}>
          Il y a {formatDistanceToNow(new Date(site.buildTime), { locale: fr })}
        </Text>

        <Button
          _hover={{
            bg: "gray.700",
            textDecoration: "none",
          }}
          as={Link}
          bg="gray.800"
          color="white"
          href="https://sfpr.admin.datocms.com/editor/item_types/1629120/items"
          rel="noopener"
          target="_blank"
        >
          Administration
        </Button>
      </Flex>
    </Flex>
  );
};

export default Layout;
