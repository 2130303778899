import { Box, BoxProps } from "@chakra-ui/react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

interface PictureProps extends BoxProps {
  alt?: string;
  image?: IGatsbyImageData;
}

const Picture = ({ alt, image, ...props }: PictureProps) => {
  return image ? (
    <Box alt="" as={GatsbyImage} image={image} {...props} />
  ) : null;
};

export default Picture;
