import { SearchIcon } from "@chakra-ui/icons";

const navbarLinks = [
  {
    label: "Qui sommes-nous ?",
    route: "/about-us",
  },
  {
    label: "Vie de la société",
    route: "/news",
  },
  {
    label: "Événements",
    route: "/events",
  },
  {
    label: "Publications",
    route: "/publications",
  },
  {
    label: "Blogs",
    route: "/blogs",
  },
  {
    label: "Contact et adhésion",
    route: "/contact",
  },
  {
    label: "Recherche",
    route: "/search",
    icon: SearchIcon,
  },
] as const;

export default navbarLinks;
