import {
  Box,
  BoxProps,
  ComponentWithAs,
  ThemingProps,
  useStyleConfig,
} from "@chakra-ui/react";
import React from "react";

interface CardProps extends ThemingProps<"Card">, BoxProps {}

const Card: ComponentWithAs<"div", CardProps> = ({
  children,
  variant,
  size,
  ...props
}) => {
  const styles = useStyleConfig("Card", {
    variant,
    size,
  });

  return (
    <Box __css={styles} {...props}>
      {children}
    </Box>
  );
};

export default Card;
